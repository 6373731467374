<template>
  <el-card
    class="box-card"
    v-loading="showLoading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-page-header
      class="product-header"
      @back="goBack"
      content="收银台"
    ></el-page-header>
    <div class="vipNo" style="margin: 2%; display: flex">
      <span>会员账号：</span>
      <!-- <el-input  v-model="vipNo"></el-input> -->
      <el-select
        v-model="vipNo"
        filterable
        remote
        reserve-keyword
        placeholder="请输入会员账号"
        :remote-method="remoteMethodVIP"
        :loading="loading"
        :disabled="vipNo !== ''"
        style="width: 25%; margin-right: 2%"
      >
        <el-option
          v-for="item in vipOptions"
          :key="item.vipValue"
          :label="`${item.name}`"
          :value="item.cardNo"
        >
        </el-option>
      </el-select>
      <span>余额：</span>
      <el-input
        disabled
        style="width: 10%; margin-right: 2%"
        v-model="vipLimit"
      ></el-input>
      <span>会员状态：</span>
      <template>
        <el-radio disabled style="margin-top: 1.5%" v-model="radio" label="1"
          >正常</el-radio
        >
        <el-radio
          disabled
          style="margin-top: 1.5%; margin-left: 1.5%"
          v-model="radio"
          label="2"
          >用户已冻结</el-radio
        >
        <div style="margin-left: 10px">
          <div class="deskState">
            <span v-if="deskStateM" :class="{ 'deskState-m': deskStateM }"
              >本</span
            ><span v-if="deskStateC" :class="{ 'deskState-c': deskStateC }"
              >信</span
            ><span v-if="deskStateF" :class="{ 'deskState-f': deskStateF }"
              >非</span
            >
          </div>
        </div>
      </template>
    </div>
    <div class="select-container">
      <header>
        <div style="width: 30%">商品</div>
        <div style="width: 20%">数量</div>
        <div style="width: 20%">单价</div>
        <div style="width: 20%">总价</div>
        <div style="width: 5%"></div>
      </header>
      <div class="container" v-for="(item, index) in productInfo" :key="index">
        <div style="width: 30%; padding-left: 2%">{{ item.name }}</div>
        <div style="width: 20%">
          <input
            style="
              text-align: center;
              background: rgba(0, 0, 0, 0);
              border: 1px solid rgba(0, 0, 0, 0);
              color: #ff6700;
            "
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            v-model="item.num"
          />
        </div>
        <div style="width: 20%; padding-left: 1%">{{ item.price }}</div>
        <div style="width: 20%; padding-left: 1%">
          {{ item.total | formatNumber }}
        </div>
        <div
          class="el-icon-delete"
          style="width: 5%; cursor: pointer; line-height: 150%"
          @click="operate(item, $event)"
        ></div>
      </div>
      <div class="final-statement">
        <div class="number">
          共计<input
            style="
              color: rgb(243, 8, 8);
              text-align: center;
              background: #f9f9f9;
              border: 1px solid rgba(0, 0, 0, 0);
              width: 25%;
            "
            v-model="number"
            readonly="readonly"
          />件商品
        </div>
        <div class="total">
          合计：<input
            style="
              color: rgb(243, 8, 8);
              text-align: center;
              background: #f9f9f9;
              border: 1px solid rgba(0, 0, 0, 0);
              width: 70%;
            "
            readonly="readonly"
            v-model="total"
          />
        </div>
      </div>
      <footer>
        <el-button
          type="primary"
          style="margin-left: 2%"
          :disabled="buttonDisabled"
          @click="dialogFormVisible = true"
          >添加商品</el-button
        >
        <el-dialog
          style="float: left"
          title="添加结算商品"
          :visible.sync="dialogFormVisible"
          :lock-scroll="false"
          :close-on-click-modal="false"
          close-on-press-escape
          v-show="dialogCancelShow"
        >
          <!-- 远程搜索要使用filterable和remote -->
          <el-select
            v-model="value"
            filterable
            remote
            reserve-keyword
            placeholder="请输入商品名称"
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="`${item.title} - ￥${item.outPrice} - ${item.amount}`"
              :value="item.productID"
            >
            </el-option>
          </el-select>

          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogCancel">取 消</el-button>
            <el-button type="primary" @click="dialogSuccess">确 定</el-button>
          </div>
        </el-dialog>
        <el-button
          type="success"
          style="margin-left: 2%"
          @click="account"
          :disabled="buttonDisabled"
          >结算</el-button
        >
        <el-button
          type="warning"
          style="margin-left: 2%"
          @click="cancel"
          :disabled="buttonDisabled"
          >取消</el-button
        >
      </footer>
    </div>
    <!-- 打印小票 -->
    <!-- <div class="print-style" id="print-body">
      <h3>易货佬出库单</h3>
      <div class="print-style-header print-style-first">
        <span>商品名称</span><span>单价</span><span>数量</span><span>总价</span>
      </div>
      <div>
        <div class="print-style-header" v-for="(item, index) in printPrams.productList" :key="index">
          <span>{{ item.name }}</span
          ><span>{{ item.price }}</span
          ><span>{{ item.num }}</span
          ><span>{{ item.total }}</span>
        </div>
      </div>
    </div>
    <el-button class="print-style-button" type="success" @click="printBody">打印</el-button> -->
  </el-card>
</template>
<script>
// 先判断会员状态，否则不允许结账
// 有没有需求做挂单

// M 本店会员
// C 授信店铺会员
import Big from "big.js";
import axios from "axios";
import moment from "moment";
import print from "print-js";
import secret from "@/utils/secret.js";

export default {
  inject: ["reload"],
  data() {
    return {
      vipNo: "",
      vipName: "",
      vipOptions: [],
      vipValue: "",
      vipLimit: "",
      radio: "0",
      productInfo: [],
      number: 0,
      total: 0,
      loading: false,
      dialogTableVisible: false,
      dialogFormVisible: false,
      title: "",
      options: [],
      list: [],
      value: "",
      buttonDisabled: true,
      dialogCancelShow: true,
      deleteProduct: [],
      printPrams: "",
      myStoreKey: "",
      creditList: [],
      deskStateM: false,
      deskStateC: false,
      deskStateF: false,
      vipState: "", // 会员归属状态
      creditStoreInfo: "", // 会员授信店铺的信息
      creditLog: {}, // 授信店铺打印信息
      showLoading: false,
      limitLogs: [],
    };
  },
  mounted() {
    this.clearShow();
    this.myStoreKey = secret.Decrypt(localStorage.getItem("user_key"));
    const myStoreKey = this.myStoreKey;
    const deskStore = true;
    axios
      .post(this.GLOBAL.api + "/store", { deskStore, myStoreKey })
      .then((res) => {
        this.creditList = res.data;
      });
  },
  created() {},
  methods: {
    goBack() {
      this.$router.push("/homepage");
    },
    account() {
      if (this.number > 0) {
        this.$confirm(
          `共计 ${this.number} 件    总价 ¥${this.total}`,
          "结账单",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "success",
            center: true,
          }
        )
          .then(() => {
            console.log("结算");
            this.showLoading = true;
            if (+this.vipLimit >= this.total) {
              const nowTime = moment().format("YYYY-MM-DD HH:mm:ss");
              const create = true;
              const productInfo = JSON.stringify(this.productInfo);
              const admin = localStorage.getItem("user_id");
              const orderInfo = {
                userID: this.vipNo,
                userName: this.vipName,
                createdTime: nowTime,
                productList: productInfo,
                orderState: "S",
                settleTime: nowTime,
                pieceNum: this.number,
                priceTotal: this.total,
                admin: admin,
                vipLimit: this.vipLimit,
              };
              this.printPrams = {
                userID: this.vipNo,
                userName: this.vipName,
                createdTime: nowTime,
                productList: this.productInfo,
                orderState: "S",
                settleTime: nowTime,
                pieceNum: this.number,
                priceTotal: this.total,
                admin: admin,
              };
              let userKey = this.myStoreKey;
              // 如果是本店会员，正常执行
              // 如果是授信店铺会员，先获取该授信店铺的授信额度，可以在开始的时候获取存在本地，然后执行扣减，接着生成订单和授信记录
              const nowMoney = {
                money: -this.total,
                logsTime: moment().format("YYYY-MM-DD HH:mm:ss"),
              };
              this.limitLogs.push(nowMoney);
              let toMoneyLogs = JSON.stringify(this.limitLogs);
              // console.log('toMoneyLogs:',toMoneyLogs);
              if (this.vipState === "M") {
                // console.log("余额：", this.limitLogs);
                // console.log("number:", this.vipNo);
                const fixMoney = true;
                const doMoney = JSON.stringify(this.limitLogs);
                const cardNo = this.vipNo
                axios
                  .post(this.GLOBAL.api + "/creat/vip", {
                    doMoney,
                    fixMoney,
                    cardNo
                  })
                  .then((fixMonehy) => {
                    console.log("收银改变金额", fixMonehy);
                    axios
                      .post(this.GLOBAL.api + "/order", {
                        create,
                        orderInfo,
                        userKey,
                      })
                      .then((res) => {
                        if (res.data.statusCode === 200) {
                          this.$message({
                            type: "success",
                            message: "结算成功!",
                          });
                          const single = true;
                          let productId = "";
                          let num = 0;
                          this.productInfo.forEach((res) => {
                            productId = res.pid;
                            num = res.num;
                            const price = this.total;
                            // console.log("this.total:", price);
                            const cardNo = this.vipNo;
                            axios
                              .post(this.GLOBAL.api + "/delete/product", {
                                single,
                                productId,
                                num,
                                price,
                                cardNo,
                                toMoneyLogs,
                              })
                              .then((data) => {
                                if (data.data.statusCode === 200) {
                                  // this.$message({
                                  //   type: "success",
                                  //   message: "结算成功!",
                                  // });
                                  // this.$confirm("是否打印小票？")
                                  //   .then(() => {
                                  //     this.printBody();
                                  //     this.$message({
                                  //       type: "success",
                                  //       message: "打印中！",
                                  //     });
                                  this.reload();
                                  //   })
                                  //   .catch(() => {
                                  //     this.$message({
                                  //       type: "warning",
                                  //       message: "本单为打印小票！",
                                  //     });
                                  //     this.reload();
                                  //   });
                                } else {
                                  this.$message({
                                    type: "warning",
                                    message: "结算出错，请联系管理员!",
                                  });
                                  this.showLoading = false;
                                  this.reload();
                                }
                              });
                          });
                        } else {
                          this.$message({
                            type: "warning",
                            message: "结算出错，请联系管理员!",
                          });
                          this.showLoading = false;
                          this.reload();
                        }
                      });
                  });
              } else if (this.vipState === "C") {
                // 判断授信金额还有没有
                if (+this.creditStoreInfo.limit_money > +this.total) {
                  const updatedLimit = true;
                  const myStoreKey = this.myStoreKey;
                  const creditStoreKey =
                    this.creditStoreInfo.credit_extensio_storeFlag;
                  const limit = +Big(this.creditStoreInfo.limit_money).minus(
                    this.total
                  );
                  let noeTotal = this.total;
                  let productInfo = this.productInfo;
                  axios
                    .post(this.GLOBAL.api + "/store", {
                      updatedLimit,
                      myStoreKey,
                      creditStoreKey,
                      limit,
                    })
                    .then((res) => {
                      this.creditLog.storeInfo.limit_money = limit;
                      this.creditLog.total = noeTotal;
                      this.creditLog.productInfo = JSON.stringify(productInfo);
                      this.creditLog.nowTime = moment().format(
                        "YYYY-MM-DD HH:mm:ss"
                      );
                      const val = this.creditLog;
                      // console.log('余额变动：',val);
                      const creditLog = true;
                      axios
                        .post(this.GLOBAL.api + "/store", { creditLog, val })
                        .then((val) => {
                          if (
                            val.status === 200 &&
                            val.data.statusCode === 200
                          ) {
                            if (
                              res.status === 200 &&
                              res.data.statusCode === 200
                            ) {
                              axios
                                .post(this.GLOBAL.api + "/order", {
                                  create,
                                  orderInfo,
                                  userKey,
                                })
                                .then((res) => {
                                  if (res.data.statusCode === 200) {
                                    this.$message({
                                      type: "success",
                                      message: "结算成功!",
                                    });
                                    const single = true;
                                    let productId = "";
                                    let num = 0;
                                    this.productInfo.forEach((res) => {
                                      productId = res.pid;
                                      num = res.num;
                                      const price = this.total;
                                      const cardNo = this.vipNo;
                                      axios
                                        .post(
                                          this.GLOBAL.api + "/delete/product",
                                          {
                                            single,
                                            productId,
                                            num,
                                            price,
                                            cardNo,
                                            toMoneyLogs,
                                          }
                                        )
                                        .then((data) => {
                                          if (data.data.statusCode === 200) {
                                            this.reload();
                                          } else {
                                            this.$message({
                                              type: "warning",
                                              message:
                                                "结算出错，请联系管理员!",
                                            });
                                            this.showLoading = false;
                                            this.reload();
                                          }
                                        });
                                    });
                                  } else {
                                    this.$message({
                                      type: "warning",
                                      message: "结算出错，请联系管理员!",
                                    });
                                    this.showLoading = false;
                                    this.reload();
                                  }
                                });
                            }
                          } else {
                            this.$message({
                              type: "error",
                              message: "授信关系记录出错，请联系管理员！",
                            });
                            this.showLoading = false;
                            this.reload();
                          }
                        });
                    });
                } else {
                  this.$message({
                    type: "info",
                    message: "店铺授信额度不足，请查看！",
                  });
                  this.showLoading = false;
                  this.reload();
                }
              } else {
                this.$message({
                  type: "error",
                  message: "非本店或授信店铺会员",
                });
                this.showLoading = false;
                this.reload();
              }
            } else {
              this.$message({
                message: "余额不足！",
                type: "worning",
              });
              this.showLoading = false;
              this.reload();
            }
          })
          .catch(() => {});
        this.showLoading = false;
      } else {
        this.$message({
          type: "error",
          message: "请添加商品后再进行结算",
        });
      }
    },
    // 打印小票
    // logPaper() {
    //   console.log("打印小票");
    // },
    // 取消收银
    cancel() {
      this.vipNo = "";
      this.productInfo = [];
      this.clearShow();
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          const title = query;
          const desk = true;
          const userKey = this.myStoreKey;
          this.options = axios
            .post(this.GLOBAL.api + "/productList", { title, desk, userKey })
            .then((item) => {
              if (+item.data.val.amount === 0) {
                this.$message({
                  type: "warning",
                  message: "该商品已无库存！",
                });
              }
              return (this.options = item.data.val);
            });
          this.loading = false;
        }, 200);
      } else {
        this.options = [];
      }
    },
    clearShow() {
      this.deskStateF = false;
      this.deskStateC = false;
      this.deskStateM = false;
    },
    remoteMethodVIP(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          const cardNo = query;
          const deskVip = true;
          const myStoreKey = this.myStoreKey;
          this.vipOptions = axios
            .post(this.GLOBAL.api + "/creat/vip", {
              deskVip,
              cardNo,
              myStoreKey,
            })
            .then((item) => {
              // 从item.data[0].storeKey 里面判断该会员属于本店会员还是授信店铺会员再或者非本店费授信店铺会员，
              // 每次进收银页面时先存储更新本地授信店铺ley数组，便于会员归属对比
              this.creditLog["vipInfo"] = item.data[0];
              let myKey = item.data[0].storeKey;
              let keyList = this.creditList;
              var inCredit = false;
              keyList.forEach((res) => {
                if (res.credit_extensio_storeFlag === myKey) {
                  this.creditStoreInfo = res;
                  return (inCredit = true);
                }
              });
              // console.log("now vip info:", item.data[0]);
              // 本店会员
              if (myKey === this.myStoreKey) {
                this.deskStateM = true;
                this.deskStateC = false;
                this.deskStateF = false;
                this.vipState = "M";
                this.vipName = item.data[0].name;
                this.limitLogs = JSON.parse(item.data[0].moneyLogs);
                return (this.vipOptions = item.data);
              } else if (inCredit) {
                this.deskStateM = false;
                this.deskStateC = true;
                this.deskStateF = false;
                this.vipState = "C";
                this.creditLog["storeInfo"] = this.creditStoreInfo;
                this.vipName = item.data[0].name;
                this.limitLogs = JSON.parse(item.data[0].moneyLogs);
                return (this.vipOptions = item.data);
              } else {
                this.deskStateM = false;
                this.deskStateC = false;
                this.deskStateF = true;
                this.vipState = "F";
                this.$message({
                  type: "error",
                  message: "非本店、非授信会员",
                });
              }
            });
          this.loading = false;
        }, 200);
      } else {
        this.vipOptions = [];
      }
    },
    dialogSuccess() {
      const productId = this.value;
      let info = "";
      axios
        .post(this.GLOBAL.api + "/product/info", { productId })
        .then((res) => {
          if (res.data[0].amount > 0) {
            info = {
              pid: res.data[0].productID,
              name: res.data[0].title,
              num: 1,
              inventory: +res.data[0].amount,
              price: res.data[0].outPrice,
              total: res.data[0].outPrice,
            };
            return this.productInfo.push(info);
          } else {
            this.$message({
              type: "error",
              message: "商品库存不足，请补充库存后进行结算！",
            });
          }
        });
      this.dialogFormVisible = false;
      this.value = "";
    },
    dialogCancel() {
      this.dialogFormVisible = false;
    },
    operate(item) {
      this.productInfo.forEach((element, index) => {
        if (element.pid === item.pid) {
          this.productInfo.splice(index, 1);
        }
      });
    },
    printBody() {
      const style =
        "@page { }" +
        "@media print {.print-style {margin: 10px auto;width: 100%;}h3 {margin: 20px auto;text-align:center;}.print-style-header {width: 100%;display: flex;}.print-style-first{margin-button:20px;}.print-style-header span {width: 25%;text-align: center;border-bottom:1px solid #f3f3f3;margin-bottom:10px;padding-button:5px;}}";
      print({
        printable: "print-body",
        type: "html",
        style: style,
        scanStyles: false,
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$confirm("正在收银操作，是否确定离开？", "收银台提醒：", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        next();
      })
      .catch(() => {
        next(false),
          this.$message({
            type: "info",
            message: "已取消离开",
          });
      });
  },
  watch: {
    vipNo: function () {
      this.buttonDisabled = this.vipNo !== "" ? false : true;
      this.vipLimit = this.vipOptions[0] ? this.vipOptions[0].vipLimit : 0;
      this.radio = this.vipOptions[0] ? this.vipOptions[0].state : 0;
      if (this.radio === "2") {
        this.$alert("请联系用户/管理员核实信息", "该用户已被冻结！", {
          confirmButtonText: "确定",
          callback: () => {
            this.reload();
          },
        });
      }
    },
    // 更改productInfo的单个商品总价
    productInfo: {
      handler(val) {
        this.number = 0;
        this.total = 0;
        val.forEach((ele, i) => {
          if (+ele.num > this.productInfo[i].inventory) {
            this.$message({
              type: "error",
              message: `商品库存还有${this.productInfo[i].inventory}件`,
            });
            this.buttonDisabled = false;
            ele.num = this.productInfo[i].inventory;
          } else {
            this.productInfo[i].total = +Big(ele.num).times(+ele.price);
          }
        });
        this.productInfo.forEach((ele) => {
          this.number = +Big(ele.num).plus(this.number);
          this.total = +Big(ele.total).plus(this.total);
        });
      },
      // 这里是关键，代表递归监听 items 的变化
      deep: true,
    },
  },
};
</script>
<style scoped>
* {
  padding: 0 0;
  margin: 0 0;
}
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 0 1%;
}
.box-card {
  width: 100%;
  height: 100%;
}
header {
  display: flex;
  width: 96%;
  line-height: 200%;
  background-color: rgb(88, 174, 255);
  text-align: center;
  margin: 2% 2% 0 2%;
}
header div {
  padding-left: 5%;
}
.container {
  display: flex;
  width: 96%;
  line-height: 200%;
  text-align: center;
  margin: 0 2%;
  line-height: 170%;
}
.container:nth-child(2n -1) {
  background-color: rgb(241, 240, 240);
}
.container:hover {
  background-color: rgb(238, 240, 122);
}
input:focus {
  outline: medium;
}
.final-statement {
  display: flex;
  justify-self: end;
  margin: 5% 0;
}
.number {
  margin-left: 17%;
}
.total {
  margin-left: 45%;
}
footer {
  margin-left: 50%;
}
footer button {
  width: 25%;
  line-height: 400%;
  font-size: 100%;
  font-weight: bold;
}
.dialog-footer button {
  line-height: 200%;
  width: 20%;
  font-weight: 400;
  font-size: auto !important;
}
.vipNo {
  width: 100%;
  line-height: auto;
}
.vipNo span {
  line-height: 250%;
}
.print-style-button {
  display: none;
}
.print-style {
  display: none;
}
.deskState span {
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50px;
  color: #fff;
  font-weight: bolder;
}
.deskState-m {
  background-color: #ff6700;
}
.deskState-c {
  background-color: rgb(12, 87, 18);
}
.deskState-f {
  background-color: rgb(146, 15, 15);
}
</style>